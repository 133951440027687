/* App.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.logo {
  width: 150px; /* Adjust size as needed */
}

.login-button {
  background-color: #473BF0;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}

.main-content {
  margin-top: 100px;
}

.main-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  font-weight: 1200; /* Thinner font weight */
}

.subtitle, .description {
  color: #6b7280;
  font-weight: 300; /* Thinner font weight */
  margin-bottom: 40px;
  line-height: 25px;
}
  
.access-button {
  background-color: #473BF0;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  margin-top: 30px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.access-button svg {
  margin-left: 10px;
}

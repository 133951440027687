/* Avatar.css */

.avatar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #473BF0;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.8em;
  white-space: nowrap;
}

.username {
  margin-right: 10px;

  height: 40px;
  line-height: 40px;
  color: rgba(0,0,0,0.7);
  font-weight: 400;
  margin: 0 10px 0 0;
}

.popover {
  position: absolute;
  top: 60px; /* Adjust based on your avatar size */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  text-align: center;
}

.popover p {
  margin: 0 0 10px 0;
}

.popover button {
  background-color: #5b21b6;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}
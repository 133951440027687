.error-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
}

.error-icon {
  margin-bottom: 20px;
}

h1 {
  font-size: 5rem;
  margin: 0;
}

h2 {
  font-size: 2rem;
  margin: 10px 0;
}

p {
  font-size: 1rem;
  color: #6c757d;
}
